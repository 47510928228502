<template>
  <v-card-text>
    <div v-if="isLoading">
      <v-progress-linear indeterminate></v-progress-linear>
    </div>
    <div v-else>
      <v-snackbar
          v-model="snackbar"
          :multi-line="true"
          absolute
          bottom
          color="warning"
          outlined
          right
          timeout="-1"
          transition="slide-x-reverse-transition"
      >
        {{ surveyError }}
        <template v-slot:action="{ attrs }">
          <v-btn color="warning" icon v-bind="attrs" @click="snackbar = false">
            <v-icon>mdi-close</v-icon>
            <span aria-hidden="true" class="d-none">Close</span>
          </v-btn>
        </template>
      </v-snackbar>
      <confirm-dialog ref="confirmDelete"></confirm-dialog>
      <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <v-form ref="form" @submit.prevent="handleSubmit(submit)">
          <v-container fluid>
            <!--      Sponsor Container starts here      -->
            <v-card class="mx-auto my-12">
              <section-header
                  :isCollapsable="false"
                  :target="() => this.$refs.sponsorContainer"
              >Sponsor
              </section-header>
              <v-card-text>
                <v-container ref="sponsorContainer" fluid>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <dt>Sponsor</dt>
                      <dd v-text="screener.sponsorName"></dd>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <dt>Engagements:</dt>
                      <dd v-if="screenerEngagements.length > 0">
                        {{ screenerEngagements.map(engagement => engagement.text).join(', ') }}
                      </dd>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
            <!--      Sponsor Container ends here      -->
            <v-card class="mx-auto mb-12">
              <section-header
                  :isCollapsable="false"
                  :target="() => self.$refs.surveyContainer"
              >
                <v-container>
                  <v-row>
                    <v-col> Manage Surveys</v-col>
                    <v-spacer></v-spacer>
                    <v-btn
                        v-if="showAddSurveyButton"
                        class="mt-1"
                        color="primary"
                        @click="addSurvey"
                    >
                      <v-icon>mdi-plus</v-icon>
                      Add New Survey
                    </v-btn>
                    <v-btn
                        v-if="showAddSurveyButton"
                        :to="{
                         name: 'SurveyAssociations',
                         params: { screenerId: screener.id },
                       }"
                        class="mt-1 ml-3 d-none"
                        color="warning"
                    >
                      <v-icon>mdi-plus</v-icon>
                      Associate Existing Surveys
                    </v-btn>
                  </v-row>
                </v-container>
              </section-header>
              <v-container ref="surveyContainer" fluid>
                <v-tabs
                    background-color="primary"
                    center-active
                    dark
                    show-arrows
                >
                  <v-tabs-slider color="white"></v-tabs-slider>
                  <draggable v-model="surveyTabs" class="v-slide-group__wrapper">
                    <v-tab
                        v-for="survey in screenerSurveys"
                        :key="survey.id"
                        @click="changeSurveyTab(survey.id)"

                    >

                      {{ survey.title }}

                      <v-icon
                          :ref="'tab-icon-' + survey.id"
                          class="d-none"
                          color="red"
                      >
                        mdi-alert-circle-outline
                      </v-icon>
                    </v-tab>
                  </draggable>

                  <v-tab-item
                      v-for="(survey) in screenerSurveys"
                      :key="survey.id"
                      eager
                  >
                    <validation-observer :ref="'observer-' + survey.id">
                      <v-card class="mx-auto my-12">
                        <v-container ref="pagesContainer" fluid>
                          <!--                      SURVEY TITLE EDIT  STARTS HERE -->
                          <v-row>
                            <v-col class="mt-4" cols="12" sm="4">

                              <v-container fluid>
                                <template v-if="isSurveyTitleChange">
                                  <validation-observer :ref="'surveyTitleObserver'+survey.id">
                                    <validation-provider
                                        v-slot="{ errors }"
                                        name="label"
                                        rules="required|max:255"
                                    >
                                      <v-text-field
                                          :ref="'surveyTitle'+survey.id"
                                          v-model="survey.title"
                                          :error-messages="errors"
                                          aria-required="true"
                                          counter
                                          hint="The title of the Survey"
                                          label="Survey Title"
                                          maxlength="255"
                                          @blur="toggleSurveyTitleChange(survey.id)"
                                      ></v-text-field>
                                    </validation-provider>
                                  </validation-observer>
                                </template>
                                <template v-else>
                                  <span class="text-h6">{{ survey.title }}</span>
                                  <v-btn
                                      v-if="survey.title !== 'DOB'"
                                      class="ml-2"
                                      color="primary"
                                      small
                                      @click="toggleSurveyTitleChange(survey.id)"
                                  >
                                    <v-icon aria-label="Change Page Title">mdi-pencil</v-icon>
                                    <span aria-hidden="true" class="d-none normal-font">Change Survey Title</span>
                                  </v-btn>
                                  <!-- Survey Delete button -->
                                  <v-btn
                                      v-if="survey.title !== 'DOB'"
                                      :loading="isEditingSurvey"
                                      small
                                      color="error"
                                      @click="deleteSurvey(survey.id)"
                                      class="ml-2"
                                    >
                                    <v-icon aria-label="Delete Survey"> mdi-delete</v-icon>
                                    <span class="d-none normal-font" aria-hidden="true">Delete Survey</span>
                                  </v-btn>
                                </template>

                              </v-container>
                            </v-col>

                            <v-spacer></v-spacer>
                            <v-btn
                                v-if="survey.title !== 'DOB'"
                                :loading="isEditingSurvey"
                                class="my-5"
                                color="primary"
                                @click="addPage()"
                            >
                              <v-icon aria-label="Add Page"> mdi-plus</v-icon>
                              Add Page
                            </v-btn>
                          </v-row>
                          <!--                        {{survey.surveyData.pages}}-->
                          <!--                      SURVEY TITLE EDIT  ENDS HERE -->

                          <!--                      PAGES STARTS HERE -->
                          <v-tabs
                              background-color="primary"
                              center-active
                              dark
                              show-arrows
                          >

                            <v-tabs-slider color="white"></v-tabs-slider>
                            <draggable v-model="tabs" class="v-slide-group__wrapper">
                              <v-tab
                                  v-for="(surveyData, pageId) in survey.surveyData.pages"
                                  :key="pageId"
                                  @change="tabChanged()"
                              >
                                <template v-if="survey.surveyData.pages[pageId]">
                                  {{ survey.surveyData.pages[pageId].title }}
                                </template>
                                <v-icon
                                    :ref="'tab-icon-' + pageId"
                                    class="d-none"
                                    color="red"
                                >
                                  mdi-alert-circle-outline
                                </v-icon>
                              </v-tab>
                            </draggable>
                            <v-tab-item
                                v-for="(page, pageId) in survey.surveyData.pages"
                                :key="pageId"
                                eager
                            >


                              <validation-observer :ref="'observer-' + pageId">
                                <v-container fluid>
                                  <v-tabs v-model="activeLanguageTab" vertical>
                                    <v-tab
                                        @change="tabChanged()"
                                        @click="updateActiveLanguageTab(0)"
                                    >English
                                      <v-icon
                                          :ref="'tab-icon-' + pageId + '-english'"
                                          class="d-none"
                                          color="red"
                                      >
                                        mdi-alert-circle-outline
                                      </v-icon>
                                    </v-tab
                                    >
                                    <v-tab
                                        v-for="(
                                          languageOption, index
                                        ) in surveyAdditionalLanguages"
                                        :key="languageOption.id"
                                        @change="tabChanged()"
                                        @click="updateActiveLanguageTab(index + 1)"
                                    >{{ languageOption.text }}
                                      <v-icon
                                          :ref="'tab-icon-' + pageId + '-' + languageOption.id"
                                          class="d-none"
                                          color="red"
                                      >
                                        mdi-alert-circle-outline
                                      </v-icon>
                                    </v-tab
                                    >
                                    <v-tab-item
                                        eager
                                        reverse-transition="slide-y-reverse-transition"
                                        transition="slide-y-transition"
                                    >
                                      <validation-observer
                                          :ref="'observer-' + pageId + '-english'"
                                      >
                                        <keep-alive>
                                        <page
                                            :id="pageId"
                                            :key="pageId"
                                            :ref="'page-' + pageId"
                                            class="item"
                                            @deleteSurveyPage="deletePage"
                                            @updateSurvey="updateSurvey"
                                        >
                                        </page>
                                        </keep-alive>
                                      </validation-observer>
                                    </v-tab-item>
                                    <v-tab-item
                                        v-for="languageOption in surveyAdditionalLanguages"
                                        :key="languageOption.id"
                                        eager
                                        reverse-transition="slide-y-reverse-transition"
                                        transition="slide-y-transition"
                                    >
                                      <validation-observer
                                          :ref="'observer-' + pageId + '-' + languageOption.id"
                                      >
                                        <secondary-page
                                            :id="pageId"
                                            :key="pageId + '-' + languageOption.id"
                                            :ref="
                                            'secondaryPage-' +
                                            pageId +
                                            '-' +
                                            languageOption.id
                                          "
                                            :languageTarget="languageOption"
                                            :questionUpdated="questionUpdated"
                                            class="item"
                                            :identity="pageId"
                                        >
                                        </secondary-page>
                                      </validation-observer>
                                    </v-tab-item>
                                  </v-tabs>
                                </v-container>
                              </validation-observer>
                            </v-tab-item>
                          </v-tabs>
                          <!--                      PAGES ENDS HERE -->
                        </v-container>
                      </v-card>

                    </validation-observer>

                  </v-tab-item>
                </v-tabs>
              </v-container>
            </v-card>


          </v-container>
        </v-form>
      </validation-observer>
    </div>
  </v-card-text>
</template>

<script>
import Vue from "vue"; // Import Vue since we need to use the KeepAlive component
import {mapActions, mapGetters, mapState} from "vuex";
import {required} from "vee-validate/dist/rules";
import {extend, ValidationObserver, ValidationProvider} from "vee-validate";
import Page from "@admin/components/surveys_new/Page.vue";
import SecondaryPage from "@admin/components/surveys_new/SecondaryPage.vue";
import ConfirmDialog from "@utils/components/ConfirmDialog.vue";
import SectionHeader from "@admin/components/SectionHeader.vue";
import draggable from "vuedraggable";


extend("required", {
  ...required,
  message: "Please provide a value.",
});

export default {
  name: "ScreenerSurveysEdit",
  components: {
    SectionHeader,
    ValidationObserver,
    ValidationProvider,
    Page,
    ConfirmDialog,
    draggable,
    SecondaryPage,
    // Use <keep-alive> instead of KeepAlive
    keepAlive: Vue.options.components.KeepAlive,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoading: true,
      isSubmitting: false,
      snackbar: false,
      self: this,
      activeLanguageTab: 0,
      questionUpdated: 0,
      secondaryLanguageOptions: [],
      isSurveyTitleChange: false,
      surveyTabError: [],
      lastSurveyTabId: null,
    };
  },
  computed: {
    ...mapState("screeners", {
      screener: "screener",
      screenerEngagements: "screenerEngagements",
      screenerAdditionalLanguages: "screenerAdditionalLanguages"
    }),
    ...mapGetters("screenerSurveys", {
      getSurveyPageIds: "surveyPageIds",
      isEditingSurvey: "isEditingSurvey",
    }),
    ...mapState("screenerSurveys", {
      screenerSurveys: "screenerSurveys",
      screenerSurveysCount: "screenerSurveysCount",
      screenerSurveyList: "screenerSurveyList",
      surveyError: "error",
      screenerIdFromStore: "screenerId",
      surveyIndex: "surveyIndex",

    }),
    ...mapGetters(["acceptRoles"]),
    showAddSurveyButton() {
      return this.acceptRoles(["Manager", "Admin", "Editor", "Super User"]);
    },
    surveyAdditionalLanguages() {
      return this.screenerAdditionalLanguages;
    },
    surveyPageIds: {
      get() {
        return this.getSurveyPageIds;
      },
      set(value) {
        this.changeSurveyPageOrder(value);
      },
    },
    tabs: {
      get() {
        return [...this.surveyPageIds];
      },
      set(value) {
        this.changeSurveyPageOrder(value);
      },
    },
    surveyTabs: {
      get() {
        let surveyTabs = [];
        Object.keys(this.screenerSurveys).forEach((key) => {
          surveyTabs.push(key);
        });
        return surveyTabs;
      },
    },

    cancelButtonRoute() {
      return this.redirectRoute;
    },
    redirectRoute() {
      return {
        name: "ScreenerView",
        params: {screenerId: this.id},
      };
    },
  },
  methods: {
    ...mapActions("navigationBar", [
      "clearActionButtons",
      "clearTitleObject",
      "setActionButtons",
      "setBreadcrumbs",
      "setEngagementBreadcrumb",
      "setSponsorBreadcrumb",
      "setTitleObject",
    ]),
    ...mapActions("screeners", [
        "getScreener",
        "deleteCloneScreener",
        "updateFromCloneScreener",
    ]),
    ...mapActions("questionCategories", {
      getQuestionCategories: "getQuestionCategories",
      refreshQuestionCategories: "refreshState",
    }),
    ...mapActions("tags", {
      getTags: "getTags",
      refreshTags: "refreshState",
    }),
    ...mapActions("sponsors", {
      getSponsor: "getSponsor",
    }),
    ...mapActions("screenerSurveys", {
      getScreenerSurveys: "getScreenerSurveys",
      associateScreenerSurvey: "associateScreenerSurvey",
      getScreenerSurveyWithData: "getScreenerSurveyWithData",
      addScreenerSurveys: "addScreenerSurveys",
      alterScreenerSurvey: "alterScreenerSurvey",
      addSurveyPage: "addSurveyPage",
      deleteSurveyPage: "deleteSurveyPage",
      setSurveyIndex: "setSurveyIndex",
      getSponsorSurveys: "getSponsorSurveys",
      editSurvey: "editSurvey",
      changeSurveyPageOrder: "changePageOrder",
      clearSurvey: "clearSurvey",
      addNewSurvey: "addSurvey",
      deleteFromSurvey: "deleteSurvey",
    }),
    toggleSurveyTitleChange(index = 0) {
      if (this.isSurveyTitleChange) {
        // call the action/mutation to update the survey title
        this.alterScreenerSurvey(this.screenerSurveys);
        this.$refs['surveyTitleObserver' + index][0].validate().then((success) => {
          if (success) {
            this.isSurveyTitleChange = false;
          }
        });
      } else {
        this.isSurveyTitleChange = true;
        this.$nextTick(() => {
          // Auto focus the title of the survey when a user inititates a title change
          this.$refs['surveyTitle' + index][0].$el.querySelector('input').focus();
        });
      }
    },
    addPage() {
      // Don't allow for adding a new page while a survey is being edited
      if (this.isEditingSurvey) return;
      this.addSurveyPage();
    },
    async addSurvey() {
      if (this.isEditingSurvey) return;
      let screenerIdForNewSurvey = this.id;
      if (this.screenerIdFromStore !== null) {
        screenerIdForNewSurvey = this.screenerIdFromStore;
      }
      await this.addNewSurvey({
        screenerId: screenerIdForNewSurvey, title: 'New Survey'
      });

    },
    deletePage(surveyId, id) {
      this.$refs.confirmDelete
          .open(
              "Confirm",
              "<div>" +
              "<p>Are you sure you want to delete this page?</p>" +
              "</div>"
          )
          .then((confirm) => {
            if (confirm) {
              this.deleteSurveyPage({surveyId: surveyId, id: id});
            }
          });
    },
    tabChanged() {
      this.updateAllTabIcons();
    },
    async updateAllTabIcons() {
      // THIS METHOD IS IN PROGRESS. (Validation is not working properly. validation is only working on active survey tab)
      let self = this;
      let newPageIds = [];
      let surveyIds = [];

      //get all identities
      //add any additional languages identities
      for (let surveyId in this.screenerSurveys) {
        if (this.screenerSurveys[surveyId]) {

          const surveyItem = this.screenerSurveys[surveyId];
          for (let pageId in surveyItem.surveyData.pages) {

            surveyIds[pageId] = surveyId;

            //add default english
            newPageIds.push(pageId);
            newPageIds.push(pageId + "-english");

            if (this.surveyAdditionalLanguages.length) {
              for (let j in this.surveyAdditionalLanguages) {
                newPageIds.push(
                    pageId + "-" + this.surveyAdditionalLanguages[j].id
                );
              }
            }
          }
        }
      }

      //for each identity, validate and show validation icon if invalid
      await newPageIds.forEach(function (id) {
        let pageObserver = null;
        if (
            self.$refs["observer-" + id] !== undefined &&
            self.$refs["observer-" + id][0] !== undefined
        ) {
          pageObserver = self.$refs["observer-" + id][0];
        }

        if (pageObserver !== null) {
          let tabIcons = self.$refs["tab-icon-" + id];
          let tabSurveyIcons = [];
          if (surveyIds[id]) {
            tabSurveyIcons = self.$refs["tab-icon-" + surveyIds[id]];
          }

          if (!Array.isArray(tabIcons)) {
            tabIcons = [tabIcons];
          }
          if (!Array.isArray(tabSurveyIcons)) {
            tabSurveyIcons = [tabSurveyIcons];
          }

          pageObserver.validate().then(async (isValid) => {
            for (let i in tabIcons) {
              if (tabIcons[i] !== undefined) {
                if (!isValid) {
                  let surveyId = surveyIds[id];
                  if (surveyId !== undefined && !self.surveyTabError.includes(surveyId)) {
                    self.surveyTabError.push(surveyId);
                  }

                  tabIcons[i].$el.classList.remove("d-none");
                  if (tabSurveyIcons[i]) {
                    tabSurveyIcons[i].$el.classList.remove("d-none");
                  }
                } else {
                  tabIcons[i].$el.classList.add("d-none");

                  if (surveyIds[id] !== undefined && surveyIds[id] === self.lastSurveyTabId) {
                    const indexToRemove = self.surveyTabError.indexOf(surveyIds[id]);
                    if (indexToRemove !== -1) {
                      self.surveyTabError.splice(indexToRemove, 1);
                    }
                    if (tabSurveyIcons[i]) {
                      tabSurveyIcons[i].$el.classList.add("d-none");
                    }
                  }
                }
              }
            }
          });
        }
      });
    },
    updateSurvey() {
      this.questionUpdated++;
    },
    async submit() {
      //update all tab validation icons
      await this.changeSurveyTab(this.surveyIndex);

      // Don't allow for submitting if the submission process was already initiated or the current contextual
      // survey is already indicated to be saved (a local submission is tracked in addition to isEditingSurvey
      // to account for restricting this process while any logic that needs processing is completed
      // after a survey is finished being edited)

      if (
          this.isSubmitting ||
          this.isEditingSurvey ||
          !(await this.$refs.observer.validate()) ||
          this.surveyTabError.length > 0
      ) {
        console.log("InValid Data");
        return;
      }

      this.isSubmitting = true;

      const promises = [];

      for (let surveyId in this.screenerSurveys) {
        promises.push(this.editSurvey(surveyId));
      }

      return Promise.all(promises).then(async (responses) => {
        const [surveySuccess] = responses.shift();

        if (surveySuccess) {
          // Identify what the redirect route should be before clearing survey
          // so we know where to go next
          const redirect = this.redirectRoute;
          this.clearSurvey(); // force re-pull from db

          // Update original screener with clone screener data
          await this.updateFromCloneScreener(this.id);

          this.$router.push(redirect);
        } else {
          this.snackbar = true;
        }
        this.isSubmitting = false;
      });
    },

    async changeSurveyTab(index) {
      // Check validations for previous tab
      await this.updateAllTabIcons();
      // Set the last survey tab id
      this.lastSurveyTabId = this.surveyIndex;
      // Set the survey index to the new tab
      await this.setSurveyIndex(index);
      // Check validations for current tab
      await this.updateAllTabIcons();
    },
    validateSurvey() { // not in use yet. Remove or use it for screener surveys
      let self = this;
      //add any additional languages identities
      for (let surveyId in this.screenerSurveys) {
        if (this.screenerSurveys[surveyId]) {
          const surveyItem = this.screenerSurveys[surveyId];
          let surveyObserver = null;
          if (
              self.$refs["observer-" + surveyItem.id] !== undefined &&
              self.$refs["observer-" + surveyItem.id][0] !== undefined
          ) {
            surveyObserver = self.$refs["observer-" + surveyItem.id][0];
          }

          if (surveyObserver !== null) {
            let tabIcons = self.$refs["tab-icon-" + surveyItem.id];

            if (!Array.isArray(tabIcons)) {
              tabIcons = [tabIcons];
            }
            // Check if the survey is valid
            surveyObserver.validate().then((isValid) => {
              for (let i in tabIcons) {
                if (tabIcons[i] !== undefined) {
                  if (!isValid) {
                    tabIcons[i].$el.classList.remove("d-none");
                  } else {
                    tabIcons[i].$el.classList.add("d-none");
                  }
                }
              }
            });
          }
        }
      }
    },

    updateActiveLanguageTab(index) {
      this.activeLanguageTab = index;
    },

    deleteSurvey(surveyId) {
      if (this.isEditingSurvey) return;
      this.$refs.confirmDelete
        .open(
          "Confirm",
          "<div>" +
          "<p>Are you sure you want to delete this Survey?</p>" +
          "</div>"
        )
        .then((confirm) => {
          if (confirm) {
            this.deleteFromSurvey(surveyId);
            // Remove the survey tab error if it exists
            let indexToRemove = this.surveyTabError.indexOf(surveyId);
            if (indexToRemove !== -1) {
              this.surveyTabError.splice(indexToRemove, 1);
            }
          }
        });
    },

    async cancelEditing() {
      // delete the clone screener
      await this.deleteCloneScreener(this.id);
    }
  },
  async beforeMount() {
    this.isLoading = true;
    const promises = [];
    await this.getScreenerSurveyWithData({
      screenerId: this.id,
    });

    // Get the first key/index
    const firstKey = Object.keys(this.screenerSurveys)[0];
    // Set the survey index to the first key/index
    await this.setSurveyIndex(firstKey);
    // Set the first survey id on load as last survey tab id
    this.lastSurveyTabId = firstKey;

    promises.push(this.getQuestionCategories());
    promises.push(this.getTags());

    await Promise.all(promises);

    // Once a survey is loaded, the following promises can be handled
    const followupPromises = [];
    followupPromises.push(
        this.getScreener({
          id: this.id,
        })
    );
    await Promise.all(followupPromises);
    await this.getSponsorSurveys({
      sponsorId: this.screener.sponsorId,
    });
    await this.getSponsor(this.screener.sponsorId);

    this.setActionButtons([
      {
        component: "CancelButton",
        attributes: {
          to: {
            name: 'ScreenerView',
            params: {screenerId: this.id},
          },
          onCancel: () => this.cancelEditing(),
        },
        index: 0,
      },
      {
        component: "SaveButton",
        attributes: {
          onSubmit: () => this.submit(),
        },
        index: 1,
      },
    ]);
    this.isLoading = false;
  },
  updated() {
    //after mounted load any validation icons
    this.updateAllTabIcons();
  },
  destroyed() {
    this.clearActionButtons();
    this.clearTitleObject();
    this.clearSurvey();
    this.refreshQuestionCategories();
    this.refreshTags();
  },

};
</script>

<style scoped>
fieldset {
  border: 0;
  display: inline-flex;
}
</style>

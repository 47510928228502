<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="6">
        <validation-provider
          v-slot="{ errors }"
          :name="languageTarget.text + ' Label' + value.id"
          rules="required|max:255"
        >
          <p>Default Label: {{ optionLabel }}</p>
          <v-text-field
            outlined
            v-model="secondaryOptionLabel"
            :error-messages="errors"
            counter
            maxlength="255"
            :hint="'The ' + languageTarget.text + ' Label'"
            :label="languageTarget.text + ' Option Label'"
            required
            aria-required="true"
          ></v-text-field>
        </validation-provider>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { extend, ValidationProvider } from "vee-validate";
import {mapActions, mapGetters} from "vuex";
import { required } from "vee-validate/dist/rules.js";

extend("required", {
  ...required,
  message: "Please provide a value.",
});

export default {
  name: "QuestionOption",
  components: { ValidationProvider },
  props: {
    value: {
      type: Object,
      required: true,
    },
    // Determine if we want to allow for this option to allow for setting transitions
    allowTransitions: {
      type: Boolean,
      required: false,
      default: true,
    },
    languageTarget: {
      type: Object,
      required: true,
    },
  },
  watch: {
    allowTransitions(currentAllowance, oldAllowance) {
      // If allowing transitions was originally set to true, which means a transition could've been
      // set, and is now false, don't allow transitions to be set. We don't want to store transitions
      // for options that don't allow them
      if (oldAllowance && !currentAllowance) {
        this.optionTransition = null;
      }
    },
  },
  computed: {
    ...mapGetters("systemLanguages", ["languagesList"]),
    isLessThanMediumBreakpoint() {
      return this.$vuetify.breakpoint.sm;
    },
    optionLabel() {
      return this.value.label;
    },
    secondaryOptionLabel: {
      get() {
        let optionLabel = "";
        if (this.value && this.value.translations[this.languageTarget.value]) {
          optionLabel = this.value.translations[this.languageTarget.value].label;
        }

        if (!optionLabel) {
          optionLabel = this.value.label;
        }

        return optionLabel;
      },
      set(value) {
        let setLabel = [];
        setLabel[this.languageTarget.value] = this.value.translations[this.languageTarget.value];

        this.updateQuestionOptionLabelTranslation({
          questionId: this.value.questionId,
          optionId: this.value.id,
          languageTarget: this.languageTarget.value,
          label: value,
        });

        this.$emit("input", this.value);
      },
    },
    optionTransition() {
      return Object.hasOwn(this.value, "transition")
        ? this.value.transition
        : null;
    },
  },
  methods: {
    ...mapActions("screenerSurveys", [
      "updateQuestionOptionLabelTranslation",
    ]),
  },
};
</script>

<style scoped></style>

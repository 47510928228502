<template>
  <v-container fluid v-if="question">
    <v-card class="mx-auto">
      <v-card-title>
        <span>
          {{ question.title ? question.title : "Question Text" }}
        </span>
      </v-card-title>
      <v-card-text>
        <secondary-question-form
          v-model="question"
          :languageTarget="languageTarget"
          :questionID="id"
        />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import {mapGetters, mapActions, mapState} from "vuex";
import SecondaryQuestionForm from "@admin/components/surveys_new/SecondaryQuestionForm.vue";

export default {
  name: "SecondaryQuestion",
  components: {
    SecondaryQuestionForm,
  },
  props: {
    id: {
      required: true,
    },
    languageTarget: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters("screenerSurveys", ["isEditingSurvey"]),
    ...mapState("screenerSurveys", ["screenerSurveys", "surveyIndex"]),
    question: {
      get() {
        // Get the question to be seen. Note, technically a question can't be guaranteed to exist on the survey
        // page, so we should only render content for this component if a question is actually found
        return this.screenerSurveys[this.surveyIndex].surveyData.questions[this.id];
      },
      set(value) { // eslint-disable-line no-unused-vars
      },
    },
  },
  methods: {
    ...mapActions("surveys", {
      changeSurveyPageQuestion: "changePageQuestion",
    }),
  },
};
</script>

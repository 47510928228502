<template>
  <div>
    <h3>Option Labels</h3>
    <secondary-question-option
      v-for="(option, index) in options"
      :key="'survey-question-option' + index"
      :value="options[index]"
      :allowTransitions="allowOptionTransitions"
      :languageTarget="languageTarget"
    >
    </secondary-question-option>
  </div>
</template>
<script>
import SecondaryQuestionOption from "@admin/components/surveys_new/SecondaryQuestionOption.vue";

export default {
  name: "QuestionOptions",
  components: { SecondaryQuestionOption },
  props: {
    value: {
      type: Object,
      required: false,
      default: () => {},
    },
    languageTarget: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isLessThanMediumBreakpoint() {
      return this.$vuetify.breakpoint.sm;
    },
    options: {
      get() {
        if (this.value && this.value.options) {
          return this.value.options;
        }

        return [];
      },
      set(value) {
        this.$emit("input", { options: value });
      },
    },
    allowOptionTransitions() {
      // Don't allow for setting transitions for multi-select based options since
      // we don't want to deal with the complexities of how interpreting transition config
      // for this field type should work
      return false;
      // return this.oFormFieldTypeId !== "multi-select";
    },
  },
  methods: {
    addOption() {
      this.options = this.options.concat([{ label: "", value: "" }]);
    },
  },
};
</script>

<template>
  <v-container fluid v-if="page">
    <v-card class="mx-auto">
      <v-card-title>
        {{ pageTitle }}
      </v-card-title>
      <v-card-text>
<!--        Secondary Page Starts here-->
        <secondary-question
          v-for="questionId in pageQuestionIds"
          :key="'survey-question-' + questionId"
          :id="questionId"
          :languageTarget="languageTarget"
          class="item"
        >
        </secondary-question>
<!--        Secondary Page Ends here-->
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import SecondaryQuestion from "@admin/components/surveys_new/SecondaryQuestion.vue";

export default {
  name: "SecondaryPage",
  components: {
    SecondaryQuestion,
  },
  props: {
    identity: {
      required: true,
    },
    languageTarget: {
      type: Object,
      required: true,
    },
    questionUpdated: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isLoading: true,
      isSubmitting: false,
      /**
       * Since we need to get all questions that are related to the given page via a Vuex getter
       * that doesn't have its results cached (e.g. the getter is a function), we need to force the getter to
       * rerun manually. This requires managing a check that we review in the computed property the getter is used
       * that we can increment when we want the getter to rerun
       */
      questionIdCheck: 0,
    };
  },
  computed: {
    ...mapState("screenerSurveys", [
      "screenerSurveys",
      "surveyIndex",
    ]),
    ...mapGetters("screenerSurveys", [
        "isEditingSurvey",
        "surveyPageQuestionIds",
    ]),
    page: {
      get() {
        return this.screenerSurveys[this.surveyIndex].surveyData.pages[this.identity];
      },
    },
    pageTitle: {
      get() {
        return this.page ? this.page.title : "";
      },
    },
    pageQuestionIds() {
      this.questionIdCheck;
      return this.surveyPageQuestionIds(this.identity);
    },
  },
  watch: {
    questionUpdated() {
      this.questionIdCheck++;
    },
  },
  methods: {
    ...mapActions("surveys", {
      changeSurveyPage: "changePage",
    }),
  },
};
</script>

<style scoped></style>
